import { utils } from './_util';

export default function SmoothScroll() {
  const span = 1000; //スピード
  const effect = 'easeOutExpo'; //アニメーション

  $(document).on('click', 'a.scroll', function () {
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
      $(this).blur();
      //var t = ( window.chrome || 'WebkitAppearance' in document.documentElement.style )? 'body' : 'html';
      var t = $(utils.isHtmlScroll() ? 'html' : 'body');
      $(t).queue([]).stop();
      var $targetElement = $(this.hash);
      var scrollTo = $targetElement.offset().top;
      var maxScroll = 0;
      if (window.scrollMaxY) {
        maxScroll = window.scrollMaxY;
      } else {
        maxScroll = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      }
      if (scrollTo > maxScroll) {
        scrollTo = maxScroll;
      }
      $(t).animate({ scrollTop: scrollTo }, span, effect, function () {
        setTimeout(function () {
          $('#globalNavi, #btnMenu a').removeClass('active');
        }, 400);
      });
      return false;
    }
  });
}