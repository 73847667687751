
export const utils = {
  isHtmlScroll: () => {
    const html = $('html'), top = html.scrollTop();
    const el = $('<div/>').height(10000).prependTo('body');
    html.scrollTop(10000);
    const rs = !!html.scrollTop();
    html.scrollTop(top);
    el.remove();
    return rs;
  },

  typeCheck: () => {
    $('.entry_body p').each(function () {
      if ($(this).find('img').length > 0) {
        $(this).addClass('img');
      }
      else if ($(this).find('iframe').length > 0) {
        var str = $(this).find('iframe').attr('src');
        if (str.match(/youtube/) || str.match(/vimeo/)) {
          $(this).addClass('mov');
        }
      }
      else {
        $(this).addClass('txt');
      }
    });
  }
};