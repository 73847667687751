import SmoothScroll from './module/_smoothscroll';


(() => {
  document.addEventListener('DOMContentLoaded', () => {
    $('.target').scrollClass();
    SmoothScroll();
  }, false);
})();



